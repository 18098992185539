import React from "react"
import { Box } from "@chakra-ui/core"

import SEO from "../../components/boilerplate/seo"
import Heading from "../../components/typography/Heading"
import Body from "../../components/typography/Body"

const InvalidTokenPage = () => (
  <Box pt="12rem" textAlign="center">
    <SEO title="Invalid Token" />
    <Heading mb="3rem">Invalid Token</Heading>
    <Body mb="6rem">The account token is expired or invalid</Body>
  </Box>
)

export default InvalidTokenPage
